import logoLink from '../resources/rhe.svg';
import companyLogoLink from '../resources/rhe.svg';
import footerLogoLink from '../resources/rhe_emeye-t-inside_logo.svg';

export const cssVariables = {
    background_color: 'rgb(238, 238, 238)',
    logo_max_width: '197px',

    primary_light: 'rgb(153, 203, 195)',
    primary: '#e30613',
    text_on_primary: 'white',
    text_on_primary_caller: 'rgb(120, 120, 120)',

    secondary: '#383838',
    text_on_secondary: 'white',
    focus_active: '#e30613',

    font: '"SuisseIntl-Medium", Helvetica, Arial, sans-serif',
    link_color: 'rgb(0, 131, 177)',
    font_color: '#383838',
    font_weight: '400',

    border_radius: '20px',
    border_width: '2px',
    border_style: 'solid',
    border_radius_narrow: '10px',
    border_radius_super_narrow: '5px',
    border_width_nav: '8px',

    disabled: '#dbdbdb',

    gridbox_header_bg: 'white',
    gridbox_header_text: '#383838',
    gridbox_padding: '8px',
    gridbox_header_padding: '8px',

    red: 'red',
    green: 'green',
    connecting: '#c0c0c0',
    connected: '#00aa00',
    aborted: '#ff0000',
    paused: '#EF6E00',

    header_footer_seperator: '#e30613',
    panel_highlight: '#e30613',

    chat_background_right: '#F9CDD0',
    chat_background_left: '#D7D7D7',

    active: '#CCFFCC',
    inactive: '#ffcccc',
    text_on_active: '#383838',
    text_on_inactive: '#383838',

    dropdown_background: '#CDECF9',

    phonebook_dropdown: '#e30613',
    phonebook_dropdown_svg: '#fff',

    pointer: '#e30613',

    toggle_switch: 'rgb(120, 120, 120)',
};

export const logo = logoLink;
export const companyTitle = '';
export const companyLogo = companyLogoLink;
export const footerLogo = footerLogoLink;
